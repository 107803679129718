<template lang='pug'>
  div
    app-dialog(
      :isModal="true",
      ref="theDialog",
      @cancel="cancelDialog",
      @save="saveDialog",
      :saveButtonLabel='ehrText["epcd.saveButtonText"]',
      :disableSave="disableSave",
      has-left-button
    )
      h2(slot="header") {{ ehrText["epcd.title"] }}
      div(slot="body")
        div(class="intro-container") {{ ehrText["epcd.instructions"] }}
        div.search-inputs
          div.input-container
            div
              label(for="familyName") {{ ehrText["epcd.familyLabel"] }}
              input(
                :class='{isInvalid: errorFamilyName}',
                id='familyName',
                ref='familyName',
                required,
                type="text",
                v-model='familyName'
              )
            div {{ errorFamilyName }}
          div.input-container
            div
              label(for="givenName") {{ ehrText["epcd.givenLabel"] }}
              input(
                :class='{isInvalid: errorGivenName}',
                id='givenName',
                type="text",
                v-model='givenName'
              )
            div {{ errorGivenName }}
          div.input-container
            div
              label(for="dob") {{ ehrText["epcd.dobLabel"] }}
              ehr-date-of-birth-input(id="dob", name="dob", :key="dobKey", :required="true", v-model="dobDetails")
              p(v-if="dobDetails") Age: {{ dobDetails.age }}
          div.input-container
            div
              label(for="mrnValue") {{ ehrText["epcd.mrnLabel"] }}
              input(
                disabled=true,
                id='mrnValue',
                type="text",
                v-model='mrnValue'
              )
        //div PD: {{ patientDataToEhrData }}
</template>

<script>
import AppDialog from '@/app/components/AppDialogShell.vue'
import UiSpinnerSmall from '@/app/ui/UiSpinnerSmall.vue'
import StoreHelper from '@/helpers/store-helper'
import { t18EhrText } from '@/helpers/ehr-t18'
import EhrDateOfBirthInput from '@/inside/components/EhrDateOfBirthInput.vue'

export default {
  data () {
    return {
      errorList: [],
      mrnValue: '',
      familyName: '',
      givenName: '',
      dobDetails: null,
      dobKey: 0
    }
  },
  components: { EhrDateOfBirthInput, UiSpinnerSmall, AppDialog },
  computed: {
    ehrText () { return t18EhrText() },
    disableSave () { return !this.isValid },
    isLoading () { return StoreHelper.isLoading() },
    errorFamilyName () { return !this.familyName ? this.ehrText['epcd.invalid.familyName'] : undefined},
    errorGivenName () { return !this.givenName ? this.ehrText['epcd.invalid.givenName'] : undefined},
    isValid: function () {
      let valid = true
      valid = valid && this.dobDetails
      valid = valid && !this.errorFamilyName
      valid = valid && !this.errorGivenName
      return valid
    },
    patientData () {
      const age = this.dobDetails && this.dobDetails.age
      const dobStr = this.dobDetails && this.dobDetails.date
      const pd = {}
      pd.ageValue = age
      pd.mrnValue = this.mrnValue
      pd.familyName = this.familyName
      pd.givenName = this.givenName
      pd.dobStr = dobStr
      return pd
    },
    patientDataToEhrData () {
      let pd = this.patientData
      let careTeamMemberName = StoreHelper.getSimSignOnName()
      let careTeamMemberProfession = StoreHelper.getSimSignOnProfession()
      let simTime = StoreHelper.getSimTime()
      simTime = simTime === '0000' ? '0930' : simTime
      const { givenName, familyName, ageValue,  dobStr, mrnValue  } = pd
      let ehrData
      ehrData = {
        meta: {
          simTime: {
            visitDay: 0,
            visitTime: simTime
          }
        },
        demographics: {
          mrn: mrnValue,
          givenName: givenName,
          familyName: familyName,
          personAge: ageValue,
          dateOfBirth: dobStr
        },
        careTeam: {
          teams: [
            {
              name: careTeamMemberName,
              profession: careTeamMemberProfession,
              teams_id: 'careTeam.teams.0'
            }
          ],
        },
        visit: {
          admissionTime: simTime,
          // "status": "Admission in progress",
          table: [
            {
              table_name: careTeamMemberName,
              table_profession: careTeamMemberProfession,
              table_day: '0',
              table_time: simTime,
              location: this.ehrText['epcd.default.location'],
              transferInDay: '0',
              transferInTime: simTime,
              table_id: 'visit.table.0'
            }
          ]
        }
      }
      return ehrData
    },

  },
  methods: {
    cancelDialog: function () {
      this.clearInputs()
      this.$refs.theDialog.onClose()
    },
    clearInputs: function () {
      this.mrnValue = ''
      this.familyName = ''
      this.givenName= ''
      this.dobDetails = null
      this.monthValue = 6
      this.dayValue = 12
    },
    showPatientCreateDialog (mrn) {
      this.dobKey++ // by changing the key we force Vue to rerender the dob input and that gives the user an empty date input
      this.clearInputs()
      this.mrnValue = mrn
      this.$refs.theDialog.onOpen()
      this.$nextTick(() => {
        this.$refs.familyName.focus()
      })
    },
    saveDialog: async function () {
      this.$refs.theDialog.onClose()
      let ehrData = this.patientDataToEhrData
      let payload = {
        patientId: this.mrnValue,
        ehrData: ehrData
      }
      this.$emit('createPatient', payload)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../scss/definitions';
.intro-container {
  margin-bottom: 1rem;
}
.search-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
}
.input-container {
  display: flex;
  flex-direction: column;
  & div:first-child {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }
  & div:nth-child(2) {
    min-height: 2rem;
  }

  & label {
    min-width: 7rem;
    text-align: right;
  }
}
</style>
