<template lang='pug'>
  div(class="patient-tab",
    :class='{ patientTabActive: selected, disabled: isDisabled }')
    div(class="name-part")
      a(@click="selectPatient()", href='#', :class='{ disabled: isDisabled }') {{ familyName }}
      button(
        v-if='isSeedEditing',
        class='close-button',
        :disabled='isDisabled',
        @click="removePatient") x
    div(class="bottom-part")
</template>

<script>
import StoreHelper from '@/helpers/store-helper'

export default {
  props: {
    selected: { type: Boolean, default: false},
    dbObject: { type: Object }
  },
  computed: {
    _id () { return this.dbObject._id },
    familyName () {
      let fName = this.keyData.familyName || ''
      fName = this.truncate(fName, 8)
      fName = fName && fName.length > 2 ? fName : fName.padEnd(3,'-')
      return fName
    },
    isDisabled () { return this.isLoading || this.$store.state.system.isEditing },
    isLoading () { return StoreHelper.isLoading() },
    isSeedEditing () { return StoreHelper.isSeedEditing() },
    keyData () { return this.dbObject ? this.dbObject.keyData : {}}
  },
  methods: {
    removePatient () {
      this.$emit('remove', this.dbObject._id)
    },
    selectPatient () {
      if (!this.selected) {
        this.$emit('select', this.dbObject._id)
      }
    },
    truncate (input, lim) {
      return input && input.length > lim ? `${input.substring(0, lim)}...` : input
    }
  }
}
</script>
<style lang='scss' scoped>
.disabled {
  cursor: not-allowed;
}
</style>

