<template lang="pug">
  div
    input(
      type="date"
      :class='{isInvalid: !isValid}'
      :id="id"
      :name="name"
      :required="required"
      :min="minDate"
      :max="maxDate"
      v-model="dob"
      @input="validateAndEmit"
    )
    p.error(v-if="errorMessage") {{ errorMessage }}
</template>

<script>
import { t18EhrText } from '@/helpers/ehr-t18'

export default {
  props: { id: String, name: String, required: Boolean },
  data () {
    return {
      dob: '',
      errorMessage: '',
      minDate: '1850-01-01', // pick a year that is well before anything we might need.
      maxDate: new Date().toISOString().split('T')[0], // Today's date
    }
  },
  computed:{
    ehrText () { return t18EhrText() },
    dobDate () { return this.dob ? new Date(this.dob) : null },
    isValid () {
      const dobDate = this.dobDate
      return dobDate && dobDate >= new Date(this.minDate) && dobDate <= new Date(this.maxDate)
    }
  },
  methods: {
    validateAndEmit () {
      if (this.isValid) {
        const age = this.calculateAge(this.dobDate)
        this.errorMessage = ''
        this.$emit('input', { date: this.dob, age }) // Emit value and age
      } else {
        this.errorMessage = this.ehrText['epcd.invalid.date']
        this.$emit('input', null) // Emit null if invalid
      }
    },
    calculateAge (dobDate) {
      let today = new Date()
      today.setUTCHours(0, 0, 0, 0)  // Set today's date to midnight UTC
      dobDate.setUTCHours(0, 0, 0, 0)  // Set dobDate to midnight UTC
      let age = today.getUTCFullYear() - dobDate.getUTCFullYear()  // Initial age calculation
      const monthDiff = today.getUTCMonth() - dobDate.getUTCMonth()  // Check month difference
      const dayDiff = today.getUTCDate() - dobDate.getUTCDate()      // Check day difference
      // If birthday hasn't occurred yet this year, subtract one from age
      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--
      }
      return age
    }


  }
}
</script>
